import Rails from "@rails/ujs";

import "core-js/stable";
import "regenerator-runtime/runtime";

import "bootstrap";
import "@fortawesome/fontawesome-free";


Rails.start();
